<template>
  <div class="merchant-container">
    <div style="height: 50px">
      <div style="float: left; margin: 5px 0 0 20px">
        <el-button icon="el-icon-plus" type="warning" @click="handleEdit" size="small">添加秒杀商品</el-button>
        <el-button @click="fieldSetting" type="danger" size="small">场次设置</el-button>
      </div>
      <div style="float: right">
        <el-form :inline="true" :model="queryForm" @submit.native.prevent>
          <el-form-item label="活动名称:">
            <el-input
                v-model.trim="queryForm.name"
                clearable
                placeholder="活动名称"
                size="small"
            />
          </el-form-item>
          <el-form-item>
            <el-button icon="el-icon-search" @click="queryData" type="primary" size="small">
              查询
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>

    <el-radio-group style="margin-left: 20px" v-model="queryForm.type" @change="buttonTab">
      <el-radio-button type="button"
                       v-for="item in button_state"
                       :key="item.id"
                       :label="item.id"
      >
        {{ item.name }}
      </el-radio-button>
    </el-radio-group>

    <el-table
        :data="list"
        @selection-change="setSelectRows">
      <el-table-column
          align="center"
          prop="id"
          label="ID"
          width="80"
      >
      </el-table-column>
      <el-table-column
          align="center"
          prop="name"
          label="活动名称"
      >
      </el-table-column>
      <el-table-column
          align="center"
          prop="details"
          label="活动描述">
      </el-table-column>
      <el-table-column align="center" label="秒杀时间" show-overflow-tooltip>
        <template #default="{ row }">
          <div>开始时间: {{ row.begin_time | formatDate }}</div>
          <div>结束时间: {{ row.end_time | formatDate }}</div>
        </template>
      </el-table-column>
      <el-table-column align="center" label="参与场次" show-overflow-tooltip>
        <template #default="{ row }">
          <span v-for="(item,index) in row.seckill_session" :key="index" class="site">
            {{ item.name }}
          </span>
        </template>
      </el-table-column>
      <el-table-column
          align="center"
          prop="goods"
          label="参与商品数">
      </el-table-column>
      <el-table-column
          align="center"
          :formatter="formatterState"
          prop="state"
          label="状态">
        <template #default="{row}">
          <span v-if="row.state == 1">开启</span>
          <span v-else>暂未开启</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作" width="250">
        <template #default="{ row }">
          <el-button type="text" @click="handleMessage(row)">查看活动商品</el-button>
          <el-button type="text" @click="handleEdit(row)">修改</el-button>
          <el-button v-if="row.state == 1" type="danger" @click="handleDelete(row,0)" size="small">关闭</el-button>
          <el-button v-else type="success" @click="handleDelete(row,1)" size="small">开启</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
        background
        align="center"
        :current-page="queryForm.page"
        :layout="layout"
        :page-size="queryForm.pageSize"
        :total="count"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
    />
  </div>
</template>
<script>
import {formatDate} from "@/utils/theTimeStamp";
// import Edit from './components/Edit'
import {apiUpdateSeckill, apiGetSeckillList} from "@/request/api";

export default {
  // 时间戳转换
  filters: {
    formatDate(time) {
      time = time * 1000
      let date = new Date(time)
      // console.log(new Date(time))
      return formatDate(date, 'yyyy-MM-dd')
    },
  },
  props: [],
  data() {
    return {
      activeName: '1',
      imgShow: true,
      selectRows: '',
      // listLoading: true,
      layout: 'total, sizes, prev, pager, next, jumper',
      count: 0,
      queryForm: {
        page: 1,
        pageSize: 10,
        type: 0,
        name: '',
      },
      current: 0,
      activity_state: 1,
      button_state: [
        {
          id: 0,
          name: '全部'
        },
        {
          id: 1,
          name: '未开始'
        },
        {
          id: 2,
          name: '进行中'
        },
        {
          id: 3,
          name: '已结束'
        },
        {
          id: 4,
          name: '已关闭'
        }
      ],
      list: [],
    }
  },
  computed: {},
  watch: {},
  created() {
    this.fetchData()
  },
  mounted() {
  },
  methods: {
    // 类型切换
    buttonTab(num) {
      if (num == 1) {
        this.queryForm.type = 1
      } else if (num == 2) {
        this.queryForm.type = 2
      } else if (num == 3) {
        this.queryForm.type = 3
      } else if (num == 4) {
        this.queryForm.type = 4
      } else {
        this.queryForm.type = 0
      }
      this.fetchData()
    },
    fieldSetting() {
      this.$router.push({
        path: 'fieldSetting',
      })
    },
    handleEdit(row) {
      if (row.id) {
        this.$router.push({
          path: 'limitedTimeSecondKill/revise',
          query: {
            id: row.id,
          },
        })
      } else {
        this.$router.push({
          path: 'limitedTimeSecondKill/revise',
          query: {
            id: '',
          },
        })
      }
    },
    //跳转查看参与活动商品
    handleMessage(row) {
      this.$router.push({
        path: 'limitedTimeSecondKill/activityGoods',
        query: {
          id: row.id,
        },
      })
    },
    // 删除
    handleDelete(row, num) {
      if (row.id) {
        this.$confirm('你确定要操作当前项吗', null, {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          apiUpdateSeckill({id: row.id, state: num}).then(res => {
            if (res.code == 200) {
              this.$message.success(res.message)
              this.fetchData()
            } else {
              this.$message.error(res.message)
            }
          })
        })
      } else {
        this.$message.error('未选中任何行')
        return false
      }
    },
    setSelectRows(val) {
      this.selectRows = val
    },
    handleSizeChange(val) {
      this.queryForm.pageSize = val
      this.fetchData()
    },
    handleCurrentChange(val) {
      this.queryForm.page = val
      this.fetchData()
    },
    queryData() {
      this.queryForm.page = 1
      this.fetchData()
    },
    async fetchData() {
      this.listLoading = true
      apiGetSeckillList(this.queryForm).then(res => {
        if (res.code == 200) {
          this.list = res.data.list
          this.count = res.data.count
        }
      })
      this.listLoading = false
    },

    // 状态
    formatterState: function (row) {
      return row.state == '0' ? '未开始' : row.state == '1' ? '进行中' : row.state == '2' ? '已结束' : row.state == '3' ? '已关闭' : '暂无'
    },
  }
}

</script>
<style>
.site {
  background-color: #ecf5ff;
  border-color: #d9ecff;
  color: #409eff;
  display: inline-block;
  height: 32px;
  padding: 0 10px;
  line-height: 30px;
  font-size: 12px;
  color: #409eff;
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
  box-sizing: border-box;
  white-space: nowrap;
  margin-right: 5px;
}
</style>
